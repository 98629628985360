import React from 'react';

const Design = () => {
  return (
    <div id="design">
      <button id="red"></button>
      <button id="yellow"></button>
      <button id="green"></button>
    </div>
  )
}

export default Design;